.bd-navbar {
  min-height: 4rem;
  background-color: #563d7c;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
}

.bd-navbar .navbar-nav .nav-link {
  color: #cbbde2;
}

.bd-navbar .navbar-nav .nav-link.active {
  font-weight: 500;
}

.navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.btn-bd-download {
  font-weight: 500;
  color: #ffe484;
  border-color: #ffe484;
}
.btn-bd-download:hover, .btn-bd-download:active {
  color: #563d7c;
  background-color: #ffe484;
  border-color: #ffe484;
}

.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0,0,0,.05);
}

.bd-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  font-weight: 500;
  color: rgba(0,0,0,.65);
}

.bd-toc-item.active>.bd-toc-link {
  color: rgba(0,0,0,.85);
}

.bd-sidebar .nav>li>a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  color: rgba(0,0,0,.65);
}

.bd-sidebar .nav>.active:hover>a, .bd-sidebar .nav>.active>a {
  font-weight: 500;
  color: rgba(0,0,0,.85);
  background-color: transparent;
}

.bd-toc-item:not(:first-child) {
  margin-top: 1rem;
}

.bd-example {
  position: relative;
}

.bd-example .fixed-top,
.bd-example .fixed-bottom,
.bd-example .sticky-top {
  position: absolute;
}

.bd-example::after {
  display: block;
  clear: both;
  content: "";
}

.bd-example button,
.bd-example span,
.bd-example label,
.bd-example input,
.bd-example select,
.bd-example textarea,
.bd-example file,
.bd-example a,
.bd-example .form-group .form-check label
{
  margin-left: .25rem;
  margin-right: .25rem;
}

.bd-example div>:not[class^=col]
{
  margin-left: .25rem;
  margin-right: .25rem;
}

.bd-example .btn-group button,
.bd-example .btn-group label,
.bd-example .btn-group-vertical button,
.bd-example .btn-group-vertical label,
.bd-example .input-group div,
.bd-example .input-group span,
.bd-example .input-group input,
.bd-example .form-group div,
.bd-example .form-group label,
.bd-example .form-group input,
.bd-example .form-group select,
.bd-example .form-group textarea,
.bd-example .pagination a,
.bd-example .pagination span
{
  margin-left: 0;
  margin-right: 0;
}

.bd-example .form-check input
{
  margin-left: -1.25rem;
}

.bd-example .form-check-inline input
{
  margin-left: 0;
}

.modal-body>.container-fluid>.row>[class^=col],
.modal-body>.container-fluid>.row>[class^=col]>.row>[class^=col],
.bd-example>.container>.row>.col,
.bd-example>.container>.row>[class^=offset],
.bd-example>.container>.row>[class^=col],
.bd-example>.container>.row>[class^=col]>.row>[class^=col] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86,61,124,.15);
  border: 1px solid rgba(86,61,124,.2);
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

@media (min-width: 768px) {
  .bd-navbar {
    position: -webkit-sticky!important;
    position: sticky!important;
    top: 0;
    z-index: 1001;
  }

  .bd-sidebar {
    border-right: 1px solid rgba(0,0,0,.1);
    position: -webkit-sticky!important;
    position: sticky!important;
    top: 4rem;
    z-index: 1000;
    height: calc(100vh - 4rem);
  }

  .bd-links {
    display: block!important;
    max-height: calc(100vh - 9rem);
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .bd-navbar {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-nav-scroll {
    max-width: 100%;
    height: 2.5rem;
    margin-top: .25rem;
    overflow: hidden;
    font-size: .875rem;
  }
}

@media (min-width: 1200px) {
  .bd-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}
